<template>
    <div class="myClass">
        <el-tabs v-model="active" @tab-click="handleClick">
            <el-tab-pane label="站内消息">
                <div class="my-information">
                    <!-- <p class="information-title">最近收到的消息</p> -->
                    <div class="information-list">
                        <div class="information-list-item" v-for="(item,index) in dataList" :key="index">
                            <div class="item-img">
                                <img src="../../assets/mySelf/my-information.png" alt />
                            </div>
                            <div class="item-content">
                                <div class="content-above">
                                    <p v-if="item.type == 1">订单</p>
                                    <p v-if="item.type == 2">支付</p>
                                    <p v-if="item.type == 3">直播</p>
                                    <p v-if="item.type == 4">问答</p>
                                    <p>{{item.intime}}</p>
                                </div>
                                <div class="content-lower">{{item.content}}</div>
                            </div>
                        </div>


                    </div>
                </div>
                <div class="" v-if="dataList.length">
                    <el-pagination layout="prev, pager, next"  :total="count" @current-change="currentChange"></el-pagination>
                </div>
            </el-tab-pane>
            <!-- <el-tab-pane label="客服消息"></el-tab-pane> -->
        </el-tabs>
    </div>
</template>
<script>
import { notification_list } from "../../api/myself"
export default {
    data() {
        return {
            active: 0,
            dataList:[],
            page:0,
            count:0
        }
    },
    mounted() {
        this.notification_list();
    },
    methods: {
        handleClick() { },
        notification_list() {
            var obj ={page:this.page};
            notification_list(obj).then(res => {
                // let obj = {
                //     "content": "test", "intime": "2020", "isRead": 0, "notificationId": 0, "type": 0
                // }
                // res.data.data.push(obj);
                this.dataList = res.data.data;
                this.count = res.data.count;
            })
        },
         currentChange(page){
            this.page = page-1;
            this.notification_list();
        },
    }
}
</script>
<style lang="scss" scoped>
.my-information {
    text-align: left;
    padding: 0 20px;
    .information-title {
        font-size: 18px;
        font-weight: 400;
        color: #333333;
    }
    .information-list {
        .information-list-item {
            display: flex;
            margin-top: 20px;
            padding-bottom: 20px;
            border-bottom: 1px solid #dddddd;
            &:last-child{
                border: none;
            }
            .item-img {
                width: 42px;
                height: 42px;
                img {
                    width: 100%;
                    height: 100%;
                }
            }
            .item-content {
                flex: auto;
                margin-left: 10px;
                .content-above {
                    display: flex;
                    justify-content: space-between;
                    font-size: 14px;
                    font-weight: 400;
                    color: #999999;
                }
                .content-lower {
                    color: #333333;
                }
            }
        }
    }
}
</style>